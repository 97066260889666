export enum Meta {
  Sinister = "sinister",
  Ferocious = "ferocious",
  Timeless = "timeless",
  Fusion = "fusion",
  Rose = "rose",
  Toxic = "toxic",
  Voyager = "voyager",
  Forest = "forest",
  Sorcerous = "sorcerous",
  Catacomb = "catacomb",
  Sunrise = "sunrise",
  Halloween = "halloween",
  Flying = "flying",
  Marsh = "marsh",
  Nightfall = "nightfall",
  Holiday = "holiday",
  GoBattleLeague = "gobattle",
  Premier = "premier",
  Little = "little",
  Kanto = "kanto",
  GoTeamUp = "goteamup",
}
